import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import './Dashboard.css';

const Dashboard = () => {
  const [statusData, setStatusData] = useState([]);
  const [typeData, setTypeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusRes = await axios.get('http://localhost:3001/api/vouchers/status');
        setStatusData(statusRes.data);

        const typeRes = await axios.get('http://localhost:3001/api/vouchers/types');
        setTypeData(typeRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="dashboard">
      <Typography variant="h3" gutterBottom>Voucher Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className="chart">
            <Typography variant="h5" gutterBottom>Voucher Status</Typography>
            <BarChart width={500} height={300} data={statusData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="status" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="chart">
            <Typography variant="h5" gutterBottom>Voucher Types</Typography>
            <PieChart width={400} height={300}>
              <Pie
                data={typeData}
                dataKey="count"
                nameKey="type"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                label
              >
                {typeData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </Paper>
        </Grid>
      </Grid>
      <div className="report-buttons">
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetApp />}
          href="http://localhost:3001/api/vouchers/report/xlsx"
        >
          Download XLSX
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetApp />}
          href="http://localhost:3001/api/vouchers/report/pdf"
        >
          Download PDF
        </Button>
      </div>
    </div>
  );
};

export default Dashboard;

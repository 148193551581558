import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'; // Import necessary components
import Dashboard from './components/Dashboard';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>
        <Routes> {/* Use Routes component for routing in react-router-dom v6 */}
          <Route path="/about">
            <h1>About Page</h1>
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
